@import "../../_globalColor";

.embedded-videos-main {
  padding: 50px 0;
  text-align: center;

  .embedded-videos-title {
    font-size: 52px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 30px;
  }

  .embedded-videos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1rem 1rem;
    justify-items: center;

    .embedded-video-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h3 {
        font-size: 24px;
        margin-bottom: 10px;
      }

      iframe {
        width: 100%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

/* Media Query */
@media (max-width: 1380px) {
  .embedded-videos-title {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .embedded-videos-title {
    font-size: 30px;
    text-align: center;
  }

  .embedded-videos-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}